import React from "react"
import '../assets/fonts/Poppins/Poppins-Light.ttf';
import '../assets/fonts/Poppins/Poppins-Bold.ttf';
import '../assets/fonts/Poppins/Poppins-Regular.ttf';
import "../components/layout.css";

import CursoHabitos from "../components/Cursos/Habitos"
const CursoHabitosPage = () => {
  return (
    <CursoHabitos/>
  )
}

export default CursoHabitosPage
import React, { useEffect, useState } from "react"
import SEO from "../../seo"
import {
  URL_EMAIL,
  URL_HOTMART_CURSO_HABITOS,
  URL_INSTA_FLYNOW,
  URL_WHATSAPP,
} from "../../../constants"
import { getQueryParam, getTrackingHotmart } from "../../../utils/query"
import { getTextCupom, formatToMoney } from "../../../utils/cupom"
import styled from "styled-components"
import colors from "../../../colors"
import { FaInstagram, FaWhatsapp } from "react-icons/fa"
import { MdMailOutline } from "react-icons/md"
import { Row } from "../../Ebooks"
import FAQ from "../../FAQ"
  const primary = '#6806AC'

// const Page = styled.div`
//   font-family: "Poppins", Tahoma, Geneva, Verdana, sans-serif !important;
// `

export const H1 = styled.h1`
  text-align: center;
  font-size: 2.7rem;
`
export const Contact = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: white;
`
const Space = styled.div`
  margin-top: 0.5rem;
`
export const ItemContact = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: white;
`

const Highlight = styled.span`
  color: ${colors.primary};
`

export const H2 = styled.h2`
  font-size: 2rem;
  text-align: center;
  color: ${colors.primary};
`

export const P = styled.p`
  font-size: 1.1rem;
  color: ${colors.regular};
`

const Button = styled.a`
  text-align: center;
  padding: 18px 50px;
  color: white;
  background: ${colors.degrade};
  border-radius: 40px;
  font-family: 'Poppins-Bold';
  cursor: pointer;
  transition: all 0.4s linear;
  &:hover {
    opacity: 0.9;
  }
  text-decoration: none;
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 10%;
  .video-copy {
    width: 680px;
    height: 340px;

    @media (max-width: 768px) {
      width: 360px;
      height: 180px;
    }
  }
`

export const Section = styled.section`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 15%;
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
  ${({ background }) =>
    background && `background: ${background};`}

  @media (max-width: 768px) {
    padding: 2rem 10%;
  }

  .icon {
    font-size: 10rem;
    color: ${colors.textStrong};
    cursor: pointer;
    transition: all 0.4s linear;
  }
  .icon:hover {
    color: ${colors.primary2};
  }
  input {
    padding: 15px;
    border: 5px solid ${colors.surface};
    border-radius: 12px;
    color: ${colors.primary2};
    margin-bottom: 20px;
    font-size: 1.1rem;
    min-width: 280px;
    max-width: 97%;
  }
  input:focus {
    outline: none !important;
    border-color: ${colors.surface};
    box-shadow: 0 0 10px ${colors.surface};
  }

  .button-waiting-list {
    padding: 15px 50px;
    background: ${colors.green};
    border: none;
    border-radius: 15px;
    cursor: pointer;
    color: ${colors.white};
    font-size: 1.1rem;
    font-family: 'Poppins-Bold';
  }
  .button-waiting-list:hover {
    transition: all 0.4s linear;
    box-shadow: 0 0 10px ${colors.green};
  }
  a {
    text-decoration: none;
  }
`

export const contact = [
  {
    link: URL_WHATSAPP,
    img: <FaWhatsapp className="icon" />,
    description: "31 999107753",
  },
  {
    link: URL_EMAIL,
    img: <MdMailOutline className="icon" />,
    description: "support@appflynow.com",
  },
  {
    link: URL_INSTA_FLYNOW,
    img: <FaInstagram className="icon" />,
    description: "@appflynow",
  },
]

const degradeBackground =
  "linear-gradient(297deg, #7357E1 0%, #A037E0 100%)"

export default function HabitosA() {
  const [cupom, setCupom] = useState(null)
  const [production, ] = useState(true)
  const [utm, setUtm] = useState('')
  const [email, setEmail] = useState("")
  const [test, ] = useState(new Date().getTime() % 2 === 0 ? "A" : "B")
  const [response, setResponse] = useState({loading:false})
  const vslA = "https://www.youtube-nocookie.com/embed/Hn9hzNJOTdA?autoplay=1"
  const vslB = "https://www.youtube-nocookie.com/embed/BcQqsvg6cAc?autoplay=1"


  function getUrlCheckout(){
    let url = URL_HOTMART_CURSO_HABITOS
    if(cupom){
      url = url.concat("?offDiscount=" + cupom)
    }
    if(utm){
      const prefix = url.includes("?") ? "&": "?"
      url = url.concat(prefix+'sck='+getTrackingHotmart())
    }
    return url
  }


  const sendEvent = event => {
    if (window) {
      if (window.fbq) window.fbq("track", event)
      if (window.gtag) window.gtag("event", event)
    }
  }

  useEffect(() => {
    sendEvent("view_course_habit")
    setCupom(getQueryParam("cupom"))
    setUtm(getQueryParam("utm_source"))
    // setProduction(getQueryParam("production"))
  }, [])

  function getInfo() {
    const discontPercentage = cupom
      ? Number(cupom.replace(/\D/g, "") || "0")
      : 0
    const price = 119.9
    return {
      URL_CHECKOUT: getUrlCheckout(),
      oldPrice: "247,99",
      price: formatToMoney(price),
      newPrice: cupom
        ? formatToMoney(price * (1 - discontPercentage / 100))
        : formatToMoney(price),
      textCupom: cupom
        ? getTextCupom(cupom) ||
          (discontPercentage
            ? `${discontPercentage}% DE DESCONTO! APROVEITE! 🔥`
            : null)
        : null,
      discontPercentage,
    }
  }
  const {
    URL_CHECKOUT,
    price,
    newPrice,
    oldPrice,
    textCupom,
    discontPercentage,
  } = getInfo()

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  const onSubmit = () => {
    if (!email) {
      return alert("Informe o seu email")
    }
    if (!validateEmail(email)) {
      return alert("Informe um email válido")
    }
    sendEvent("click_waiting_list_course_habit")
    setResponse({loading: true})
    // sendEvent('click_get_course_habit')
    fetch("https://api.appflynow.com/waiting-list-course-habit", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        test,
        cupom,
        utm_source: getQueryParam("utm_source"),
        utm_medium: getQueryParam("utm_medium"),
        utm_campaign: getQueryParam("utm_campaign"),
      }),
    })
      .then(res => {
        if (res.status === 200) {
          setResponse({ status: res.status,loading:false })
          alert(
            "Pronto! Te enviaremos um e-mail com um link promocional quando o curso estiver disponível!"
            )
          } else {
            res.json().then(data => {
            setResponse({loading: false, status: res.status,message: data.message })
            alert(data.message)
          })
        }
      })
      .catch(err => {
        setResponse({loading: false, message: err.message, status: 400})
      })
  }

  const onCheckout = () => {
    sendEvent("click_checkout_course_habit")
  }

  return (
    <>
      <SEO
        title="A sua vida extraordinária começa mudando seus hábitos"
        pageId="course_habitos"
        description="Você já se perguntou por que algumas pessoas alcançam o sucesso enquanto outras ficam presas em uma rotina de mediocridade? A resposta está nos hábitos! Os hábitos são a base de nossas ações diárias e, quando dominados, podem levar a uma vida extraordinária. | Curso sobre como criar bons hábitos e eliminar hábitos ruins"
        imageUrl={require("../../../assets/images/cursoHabitos.png")}
      />
      <Main>
        <H1>
          A sua <Highlight>vida extraordinária</Highlight> começa mudando{" "}
          <Highlight>seus hábitos</Highlight>
        </H1>
        <P>
          Você já se perguntou por que algumas pessoas{" "}
          <b>alcançam o sucesso </b>
          enquanto outras ficam presas em uma <b>rotina de mediocridade</b>?
          <br />
          <Space />
          A resposta está nos hábitos!
          <br />
          <Space />
          Os hábitos são a base de nossas ações diárias e, quando dominados,
          podem levar a uma <b>vida extraordinária</b>.🚀
          <br />
          <Space />
          Se você está pronto para dar um salto em direção ao seu potencial
          máximo, você está no lugar certo!
        </P>
        <iframe
          className="video-copy"
          src={vslA}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          auto
        ></iframe>
        <br />
        <Button href={"#checkout"} >QUERO UMA VIDA EXTRAORDINÁRIA</Button>
        {/* {
          textCupom && (
        <h4 style={{ background: '#dd0000', fontWeight: 'normal', color: '#fff', textAlign: 'center', padding: 5, marginTop: 15, marginRight: 10 }}>
          {textCupom}
        </h4>
          )
        } */}
      </Main>

      <Section backgroundColor={colors.surface}>
        <H2>
          Isto é o que acontece quando você NÃO sabe criar hábitos positivos…{" "}
        </H2>
        <br />
        <P>
          <ul>
            <li>
              Hábitos autodestrutivos que colocam sua saúde em risco se
              infiltram em sua rotina;
            </li>
            <li>
              Estar sempre cansado e sem disposição pode ser o seu novo
              "normal";
            </li>
            <li>Falta de foco é um verdadeiro obstáculo para o seu sucesso;</li>
            <li>A procrastinação é o seu inimigo número um;</li>
            <li>Alcançar seus sonhos parece uma tarefa impossível.</li>
          </ul>
          <br />
          Não permita que esses problemas continuem afetando sua vida!❌
          <br />
          <br />É hora de investir em si mesmo e aprender a criar hábitos
          poderosos que ajudarão a trilhar um caminho claro em direção à
          realização pessoal.
          <br />
        </P>
        <Button href={"#checkout"}>QUERO UMA VIDA EXTRAORDINÁRIA</Button>
      </Section>

      <Section>
        <H2>Para quem é o curso? 🤩</H2>
        <br />
        <P>
          O Curso “Domine seus hábitos: A chave para uma vida extraordinária” é
          para pessoas corajosas que desejam:
          <br />
          <br /> ✔️ Criar e manter bons hábitos no longo prazo para alcançar a
          vida que sempre sonhou;
          <br /> ✔️ Ter mais energia e disposição no seu dia a dia, acordando
          renovado;
          <br /> ✔️ Adquirir um estilo de vida saudável ao adotar hábitos que
          promovam seu bem-estar físico e mental;
          <br /> ✔️ Construir uma versão incrível de você mesmo, alcançando seu
          potencial máximo e vivendo uma vida extraordinária.
          <br /> <br />
          Você está pronto para mudar de vida? 🎯
        </P>
      </Section>

      <FAQ
        color={"#444"}
        background={degradeBackground}
        title="O que você vai aprender? 🧠"
        logo={require("../../../assets/images/cursoHabitos.png")}
        data={[
          {
            title: "MÓDULO - CONCEITOS BÁSICOS",
            text: (
              <>
                <ul>
                  <li>Afinal, o que é um hábito?</li>
                  <li>Como os hábitos se formam?</li>
                  <li>Criando um hábito no App Flynow</li>
                  <li>Seu ambiente molda você</li>
                </ul>
              </>
            ),
          },
          {
            title: "MÓDULO - TÉCNICAS PARA CRIAR HÁBITOS",
            text: (
              <>
                <ul>
                  <li>Leis para criar (e eliminar) hábitos</li>
                  <li>Comece pequeno (o poder dos mini-hábitos)</li>
                  <li>Use o efeito cascata ao seu favor</li>
                  <li>Tenha variações em mãos (mantenha seus hábitos)</li>
                </ul>
              </>
            ),
          },
          {
            title: "MÓDULO - ROTINAS, MOTIVAÇÃO E CONSTÂNCIA",
            text: (
              <>
                <ul>
                  <li>Como criar rotinas eficientes</li>
                  <li>Criando rotinas no App Flynow</li>
                  <li>Você precisa de motivação</li>
                  <li>O que fazer para ter mais constância?</li>
                </ul>
              </>
            ),
          },
          {
            title: "MÓDULO - CONTINUE EVOLUINDO",
            text: (
              <>
                <ul>
                  <li>A trivialidade da excelência</li>
                  <li>Como ter 10x mais energia</li>
                  <li>Desafio!</li>
                </ul>
              </>
            ),
          },
        ]}
      />

      <Section id="checkout">
        <H2>Está pronto para construir uma vida extraordinária? ✨</H2>
        <br />
        <P>
          Prepare-se <b>para desbloquear seu verdadeiro potencial </b>com o
          nosso curso e construir uma vida extraordinária baseada em hábitos
          poderosos.
          <br />
          <br />E o melhor: por um preço muito menor do que o{" "}
          <b>valor que ele pode te oferecer </b>. Afinal, coisas como liberdade,
          saúde e qualidade de vida são coisas que o dinheiro não paga!
          <br />
          {!production && (
            <>
              <br />
              Para não perder essa oportunidade,{" "}
              <b>CADASTRE SEU MELHOR E-EMAIL ABAIXO </b> para garantir um{" "}
              <b>DESCONTO EXCLUSIVO </b> quando o curso for lançado:
            </>
          )}
        </P>
        {
          production && (
          <>
          <h3>
            <s>DE R$ {oldPrice}</s>
          </h3>
          {price !== newPrice && (
            <>
              <h2>POR <s>R$ {price}</s></h2>
              <h4 style={{ background: '#dd0000', color: '#fff', textAlign: 'center', margin: 5, padding: 5 }}>
                + {discontPercentage}% OFF
              </h4>
            </>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              border: "4px solid " + primary,
              alignItems: "center",
              color: primary,
              padding: 7,
              marginTop: 5
            }}
          >
            <h6>POR APENAS 12x DE </h6>
            <h1 style={{ margin: 10 }}> R$ {formatToMoney((Number(newPrice.replace(',','.'))*1.1973)/12)} </h1>
            <h6> ou {newPrice} à vista </h6>
          </div>
          <br />
        <Button href={URL_CHECKOUT} onClick={onCheckout}>QUERO GARANTIR MEU CURSO!</Button>
            </>
          )
        }
      </Section>

        {
          !production && (
      <Section background={colors.degrade}>
        <h3 style={{ color: "white" }}>Cadastre-se na lista de espera:</h3>
        <div style={{ marginBottom: 20 }} />
        <input
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="Informe o seu melhor e-mail"
        />
        <button className="button-waiting-list" onClick={onSubmit}>
          {
            !response.loading && !response.status ? 'Quero receber uma oferta exclusiva' : response.loading ? 'Carregando...' : response.status === 200 ? 'Sucesso!' : response.message
          }
        </button>
      </Section>
          )
        }

        <FAQ
        color={"#444"}
        background={degradeBackground}
        data={[
          {
            title: "Como é o formato do curso?",
            text:
              "Aulas gravadas, que você pode assistir quando e onde quiser. :)",
          },
          {
            title: "Qual é a duração total do curso?",
            text: "O curso contém 4 módulos (total de 16 aulas).",
          },
          {
            title: "Por quanto tempo terei acesso ao curso?",
            text: "Acesso vitalício. Ou seja, você pode assistir ao curso quantas vezes quiser!",
          },
          {
            title: "E se eu tiver dúvidas?",
            text:
              "Você poderá tirar suas dúvidas dentro da própria plataforma de aulas.",
          },
          {
            title: "Quais são as opções de pagamento?",
            text:
              "Cartão de crédito (parcelado em até 12x), PIX ou saldo Hotmart. Usuários premium dos nossos apps têm 40% de desconto!",
          },
          {
            title: "E se eu não gostar do curso?",
            text:
              "Caso você não fique completamente satisfeito com o curso, basta nos avisar dentro de sete dias após a compra e nós devolveremos seu dinheiro. Simples assim!",
          },
        ]}
      />

      <Section backgroundColor={colors.surface}>
        <H2>Garantia incondicional de 7 dias ✅</H2>
        <br />
        <img
          src={require("../../../assets/images/garantia7dias.png")}
          alt="garantia-7-dias"
          style={{ height: 250 }}
        />
        <div>
          <P>
            Acreditamos tanto na qualidade e no impacto transformador do nosso
            curso que queremos eliminar qualquer dúvida ou receio que você possa
            ter.
          </P>
          <P>
            É por isso que estamos oferecendo uma{" "}
            <b>garantia incondicional de 7 dias.</b>
          </P>
          <P>
            Isso mesmo, caso você não fique completamente satisfeito com o
            curso, basta nos avisar dentro de sete dias após a compra e nós{" "}
            <b>devolveremos seu dinheiro. </b> Simples assim!
          </P>
        </div>
        <br />
      </Section>

      <Section>
        <H2>Alguma dúvida?</H2>
        <br />
        <P>Se tiver qualquer dúvida entre em contato conosco!</P>
        <Contact>
          {contact.map(i => (
            <ItemContact key={i.link}>
              <a
                href={i.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textAlign: "center" }}
              >
                {i.img}
                <h4 style={{ color: colors.textStrong }}>{i.description}</h4>
              </a>
            </ItemContact>
          ))}
        </Contact>
        <br />
      </Section>
    </>
  )
}
